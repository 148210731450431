import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
	   nativeBlue: {
		100: "#0073E6"
	   }
	},
	styles: {
		global: (props) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('#fdfeff', 'navy.900')(props),
				fontFamily: 'DM Sans',
				letterSpacing: '-0.5px'
			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: 'DM Sans'
			}
		})
	}
};
