/*
 * IMPORT
 */
import React, { Suspense, useEffect, useState } from "react"; // NPM: React package.
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state management.
import { Flex, Spinner } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.

/*
* COMPONENTS
*/
import ScrollToTop from './components/scrollToTop/index';
import './index.css'

/*
* AUTH
*/
import Context from "./context";
const SignUp = React.lazy(() => import("./views/auth/signUp/SignUpDefault.js"));
const SignIn = React.lazy(() => import("views/auth/signIn/SignInDefault.js"));
const ForgotPassword = React.lazy(() => import("views/auth/forgotPassword/index.js"));

// WEBSITE Components
const NotFound = React.lazy(() => import('./views/admin/website/notFound/index'));
const OpenOnLaptop = React.lazy(() => import('./views/admin/website/openOnLaptop/index'));
const ChatPage = React.lazy(() => import('./views/admin/website/ChatApp/index'));


/*
 * OBJECT
 */
const Index = ({ account }) => {

  console.log(account, account?.isUserLoggedIn && account.accountType === "ACCOUNT_ADMIN", "account")
  const [context, setContext] = React.useState({});
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const protectedRoutes = (
      <Switch>
        {/* IF USER IS NOT LOGGED IN */}
        {!account.isUserLoggedIn &&
            <Switch>
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/forget-password" component={ForgotPassword} />

              {/* Catch-all Redirect for any other route */}
              <Redirect to="/signin" />
            </Switch>
        }

        {account.isUserLoggedIn &&
            <Switch>
              <Route path="/" component={ChatPage} />

              {/* Catch-all Redirect for any other route */}
              <Redirect to="/" />
            </Switch>
        }

      </Switch>
  );

  return (
      <Context.Provider value={{ context, setContext }}>
        <Toaster />
        <Router>
          <ScrollToTop />
          <Suspense fallback={<Flex w='100vw' h='100vh'><Spinner color="#FFDEC7" mx='auto' my='auto' /></Flex>}>
            <Switch>
              {/* WEBSITE ROUTES */}

              {isMobileOrTablet ? (
                  <Route component={OpenOnLaptop} />
              ) : (
                  protectedRoutes
              )}

              {/* IF NO ROUTE IS AVAILABLE SHOW 404 PAGE */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </Context.Provider>
  );
};

/*
* REDUX
*/
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

/*
* EXPORT
*/
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
