/*
 * IMPORT
 */
import React from "react"; // Npm: React.
import { hydrate, render } from "react-dom";
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, split } from '@apollo/client'; // Npm: Apollo client for handling graphql.
import { createUploadLink } from 'apollo-upload-client'; // Npm: Apollo client upload link.
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'; // Npm: WebSocket link for subscriptions using graphql-ws.
import { createClient } from 'graphql-ws'; // Npm: Client creation for graphql-ws.
import { getMainDefinition } from '@apollo/client/utilities'; // Npm: Utility to determine the operation type.
import App from 'index.app.js'; // App component.
import { Provider } from 'react-redux'; // Npm: Redux provider.
import { PersistGate } from 'redux-persist/integration/react'; // Npm: Redux persist gate.
import { ChakraProvider } from "@chakra-ui/react"; // Npm: Chakra UI provider..
/*
 * PACKAGES
 */
import Redux, { Persist } from './redux';
/*
 * STYLES
 */
import theme from "theme/theme";
import "assets/css/App.css";
import "mapbox-gl/dist/mapbox-gl.css";

// For Deployment

/*
 * APOLLO CLIENT PROVIDER
 */
const ApolloClientProvider = initialState => {
  // const state = Redux.getState()
  // const token = state.auth.token

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_NODE_BACKEND,
  });

  const wsLink = new GraphQLWsLink(createClient({
    url: `${process.env.REACT_APP_NODE_BACKEND.replace(/^http/, 'ws')}`,
    options: {
      reconnect: true,
    },
  }));

  // Split link based on operation type
  const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
  );

  const _ApolloClient = new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: splitLink,
    cache: new InMemoryCache().restore(initialState || {}),
    defaultOptions: {
      query: {
        context: {
          clientName: 'default'
        }
      },
      mutate: {
        context: {
          clientName: 'default'
        }
      },
      watchQuery: {
        context: {
          clientName: 'default'
        }
      }
    }
  });

  return _ApolloClient;
}

/*
 * RENDER
 */

const Root = () => {
  return (
      <Provider store={Redux}>
        <PersistGate persistor={Persist}>
          <ChakraProvider theme={theme}>
            <ApolloProvider client={ApolloClientProvider()}>
              <App />
            </ApolloProvider>
          </ChakraProvider>
        </PersistGate>
      </Provider>
  );
};

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
